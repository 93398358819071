import { useMemo, useState } from "react";
import "./index.scss";

const AuthTextBoxWrapper = ({ children, placeholderImage, focusPlaceholderImage, eye }) => {

    const [isFocus, setIsFocus] = useState(false);

    const renderPlaceHolderImage = useMemo(() => () => {
        return isFocus ? focusPlaceholderImage : placeholderImage;
    }, [isFocus]);

    return (
        <div
            className={`authTextWrapper ${isFocus ? 'active' : ''}`}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}>
            <div className="gradientBG">
                <div className="placeholderImage">{renderPlaceHolderImage()}</div>
                {children}
                <div className="eyeImage">{eye}</div>
            </div>
        </div>
    );
}

export default AuthTextBoxWrapper;
import React, { useState } from "react";
import Emailpage from "./Emailpage";
import CompanyNameSign from "./CompanyNameSign";
import VerificationOtp from "./VerificationOtp";
import { useEffect } from "react";
import PasswordSign from "./PasswordSign";
import STORAGEKEY from "config/APP/app.config";
import AuthStorage from "helper/AuthStorage";
import queryString from "query-string";

const Signup = () => {
  const [stepper, setStepper] = useState(1);
  const query = queryString.parse(window.location.search);
  const otp = query?.code;
  useEffect(() => {
    /* Temporary patch for signup session */
    localStorage.removeItem(STORAGEKEY.signupStep);
    // AuthStorage.deleteKey(STORAGEKEY.signupStep)
    // const completedStep = AuthStorage.getStorageJsonData(STORAGEKEY.signupStep) || []
    const completedStep = localStorage.getItem(STORAGEKEY.signupStep) || [];
    if (completedStep.length)
      setStepper(completedStep[completedStep.length - 1] + 1);
    if (otp) setStepper(4);
  }, [otp]);

  const onNext = (nextStep) => {
    setStepper(nextStep);
  };
  const renderComponent = () => {
    switch (stepper) {
      case 1:
        return <Emailpage onNext={onNext} />;
      case 2:
        return <CompanyNameSign onNext={onNext} />;
      case 3:
        return <PasswordSign onNext={onNext} />;
      case 4:
        return <VerificationOtp onNext={onNext} otp={otp} />;
      default:
        return <Emailpage onNext={onNext} />;
    }
  };
  return <div>{renderComponent()}</div>;
};

export default Signup;

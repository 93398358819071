
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend'
import STORAGEKEY from 'config/APP/app.config';
import AuthStorage from 'helper/AuthStorage';
// import englishTranslation from './locales/en/englishTranslation.en.json'

// const resources = {
//   en: {
//     translation: englishTranslation
//   }
// };

const fallbackLng = AuthStorage.getStorageJsonData(STORAGEKEY.lang) ? AuthStorage.getStorageJsonData(STORAGEKEY.lang) : 'en-Us'

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJEECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  refLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
  // private: true,
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: fallbackLng,
    // fallbackLng: ['en-Us'],
    // resources,
    // detection: {
    //   order: ['htmlTag', 'cookie', 'localStorage'],
    //   caches: ['cookie', 'localStorage']
    // },

    // interpolation: {
    //   escapeValue: false,
    // },
    // resources,
    backend: locizeOptions,
    // saveMissing: true
  });

export default i18n;
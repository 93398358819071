
import React from 'react';
import { ReactComponent as Loading } from '../../assets/img/loader.svg';

export const Loader = () => {
    return (
        <div className='loader-spinner'>
            <Loading />
        </div>
    )
}


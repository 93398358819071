import ReactGA from "react-ga4";
// import TagManager from "react-gtm-module";

const ReactGAEvent = (options) => {
  ReactGA.event(options);
};

const ReactGApageview = (page) => {
  ReactGA.set({ page });
  ReactGA.send({ hitType: "pageview", page: page });
};

const ReactGAinit = () => {
  ReactGA.initialize("G-DGKKQQYHN5");
  //   TagManager.initialize({ gtmId: "GTM-5ZTQT7M" });
  // ReactGA.ga((tracker) => {
  //     store.clientId = tracker.get('clientId');
  // });
};

export { ReactGAEvent, ReactGAinit, ReactGApageview };

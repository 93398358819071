import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { ApiGet } from "../../helper/API/ApiData";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthStorage from "helper/AuthStorage";
import { useTranslation } from "react-i18next";
import SignupCommon from "./SignUpCommon";
import { useEffect } from "react";
import { ReactComponent as LockIcon } from "../../assets/img/lock.svg";
import { ReactComponent as DarkLockIcon } from "../../assets/img/lock-dark.svg";
import { ReactComponent as EyeOpenIcon } from "../../assets/img/eye-open-svgrepo-com.svg";
import { ReactComponent as EyeClosedIcon } from "../../assets/img/eye-closed-svgrepo-com.svg";
import Error from "common/Error";
import ContinueButton from "pages/componets/continueButton";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import { appSettings } from "helper/appSettings";
import STORAGEKEY from "config/APP/app.config";
import ReactGA from "react-ga4";

const PasswordSign = ({ onNext }) => {
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const { t } = useTranslation();
  const [storageData, setStorageData] = useState();
  const [privacyData, setPrivacyData] = useState([]);
  const [termsAndConditionsData, setTermsAndConditionsData] = useState([]);
  const [myPassword, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const SignupSchema = yup.object({
    termsCondition: yup
      .boolean()
      .oneOf([true], t("SignUp.Errors.termsCondition")),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required(t("SignUp.Errors.password")),
  });
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    ApiGet(
      `${appSettings?.API_BASE_URL}/info-service/api/static_info/privacyPortal`
    ).then((res) => {
      setPrivacyData(res?.response?.json_body?.data ?? []);
    });
  }, []);

  useEffect(() => {
    ApiGet(
      `${appSettings?.API_BASE_URL}/info-service/api/static_info/termsAndConditions`
    ).then((res) => {
      setTermsAndConditionsData(res?.response?.body ?? []);
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { agree: false },
    resolver: yupResolver(SignupSchema),
  });

  useEffect(() => {
    setStorageData(AuthStorage.getStorageJsonData(STORAGEKEY.userData));
  }, []);

  const handleClose = () => {
    setTerms(false);
    setPrivacy(false);
  };

  const toggleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  return (
    <SignupCommon>
      <form onSubmit={(e) => e.preventDefault()}>
        <h2 className="main-heading">{t("SignUp.Password")}</h2>
        <h3>{t("setPassword.Secure")}</h3>
        <p className="sub-heading sub-heading-left">{t("setPassword.text")}</p>
        <AuthTextBoxWrapper
          placeholderImage={<LockIcon />}
          focusPlaceholderImage={<DarkLockIcon />}
          eye={
            showPassword ? (
              <EyeOpenIcon onClick={toggleShowPassword} />
            ) : (
              <EyeClosedIcon onClick={toggleShowPassword} />
            )
          }
        >
          <input
            type={showPassword ? "text" : "password"}
            minLength={8}
            placeholder={t("setPassword.password_placeholder")}
            name="password"
            {...register("password", {
              onChange: (e) => handlePasswordChange(e),
            })}
            autoFocus
          />
        </AuthTextBoxWrapper>

        {errors?.password && <Error message={errors?.password?.message} />}
        <div className="check-box-password-main">
          <input
            type="checkbox"
            name="termsCondition"
            className="check-box-password"
            {...register("termsCondition")}
          />
          <label>
            {t("setPassword.i_agree_to_boomio")}&nbsp;
            <span
              style={{ borderBottom: "1px solid #000" }}
              onClick={() => {
                setTerms(true);
                setPrivacy(false);
              }}
            >
              {t("setPassword.terms")}
            </span>
            &nbsp;
            {t("setPassword.and")}&nbsp;
            <span
              style={{ borderBottom: "1px solid #000" }}
              onClick={() => {
                setTerms(false);
                setPrivacy(true);
              }}
            >
              {t("setPassword.privacy_policy")}
            </span>
          </label>
        </div>
        {errors?.termsCondition && (
          <Error message={errors?.termsCondition?.message} />
        )}
        <div className="check-box-password-main-bottom check-box-password-main">
          <input
            type="checkbox"
            name="agree"
            className="check-box-password"
            {...register("agree")}
          />
          <label>{t("setPassword.agree_with_boomio_updates")}</label>
        </div>
        <ContinueButton
          onSubmit={handleSubmit((data) => {
            ReactGA.event({
              action: "Set Password",
              category: "Sign up",
              value: "",
            });
            // AuthStorage.setStorageJsonData(STORAGEKEY.signupStep, [1, 2, 3]);
            localStorage.setItem(STORAGEKEY.signupStep, [1, 2, 3]);
            AuthStorage.setStorageJsonData(
              STORAGEKEY.userData,
              Object.assign(storageData, data)
            );
            // localStorage.setItem(
            //   STORAGEKEY.userData,
            //   Object.assign(storageData, data)
            // );
            onNext(4);
          })}
        />
      </form>
      {privacy && (
        <Modal
          show={privacy}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="create-coupons-modals add-customer"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontFamily: "system - ui" }}
            >
              {t("setPassword.privacy_policy")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "100%", overflowY: "auto" }}>
            <Row>
              <Col lg={2}>
                {privacyData?.map((item, index) => {
                  return (
                    <p>
                      {index + 1}. {item?.chapter}
                    </p>
                  );
                })}
              </Col>
              <Col lg={10}>
                <div className="privacy-policy">
                  {privacyData.map((item) => (
                    <div className="some-title">
                      <h1
                        dangerouslySetInnerHTML={{ __html: item?.chapter }}
                      ></h1>
                      <p
                        dangerouslySetInnerHTML={{ __html: item?.chapter_data }}
                      ></p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      {terms && (
        <Modal
          show={terms}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="create-coupons-modals add-customer"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontFamily: "system - ui" }}
            >
              {t("setPassword.terms")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "100%", overflowY: "auto" }}>
            <Row>
              <div
                dangerouslySetInnerHTML={{ __html: termsAndConditionsData }}
              />
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </SignupCommon>
  );
};
export default PasswordSign;

import "./App.css";
import "./Responsive.css";
import Pages from "./pages/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { useLocation } from "react-router";
import { appSettings } from "helper/appSettings";
import { ReactGAinit } from "../src/pages/events";

function App() {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    ReactGAinit();
    let authCookie = AuthStorage.getToken();
    if (authCookie && authCookie !== "undefined") {
      window.location = appSettings.PORTAL_APP_URL;
    } else {
      setIsAuth(false);
      const lang = AuthStorage.getStorageJsonData(STORAGEKEY.lang);
      if (lang) {
        i18n.changeLanguage(lang);
      } else {
        if (location.search === "?lng=hi") {
          AuthStorage.setStorageJsonData(STORAGEKEY.lang, "hi");
          i18n.changeLanguage("hi");
        } else if (location.search === "?lng=lt") {
          AuthStorage.setStorageJsonData(STORAGEKEY.lang, "lt");
          i18n.changeLanguage("lt");
        } else if (location.search === "?lng=nl") {
          AuthStorage.setStorageJsonData(STORAGEKEY.lang, "nl");
          i18n.changeLanguage("nl");
        } else {
          AuthStorage.setStorageJsonData(STORAGEKEY.lang, "en-us");
          i18n.changeLanguage("en-us");
        }
      }
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer position="top-right" autoClose={5000} />
      {!isAuth ? <Pages /> : null}
    </div>
  );
}

export default App;

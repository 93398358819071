const STORAGEKEY = {
    token: 'token',
    refreshToken: 'refresh-token',
    userData: 'userData',
    userId: 'userId',
    layoutData: 'layoutData',
    roles: 'roles',
    email: 'email',
    lang: 'i18nextLng',
    signupStep: 'signup-step'
};

export default STORAGEKEY

import AuthStorage from '../AuthStorage'

const axios = require('axios').default

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: false,
}

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${type}`, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser()
          window.location.href = '/'
        }
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiPost = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser()
          // window.location.href = "/"
        }
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${type}`,
        userData
      )
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          AuthStorage.deauthenticateUser()
          // window.location.href = "/"
        }
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('message') &&
          error.response.data.message
        ) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
  })
}

export const getHttpOptions = (options = defaultHeaders, token) => {
  let headers = {
    Authorization: token,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': null,
    'Access-Control-Allow-credentials': true,
  }
  // "Origin, X-Requested-With, Content-Type, Accept"

  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    headers['Authorization'] = AuthStorage.getToken() ?? ''
  }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams }
  }

  return { headers }
}

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/authentication/authSlice';
import initialReducer from './features/intialSlice'

export default configureStore({
    reducer: {
        initialState: initialReducer,
        auth: authSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
import DropdownMenu from "common/Dropdown";
import OutlineButton from "common/OutlineButton";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "../signup/Loader";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import "../../App.css";
import { Link } from "react-router-dom";
import STORAGEKEY from "config/APP/app.config";
import AuthStorage from "helper/AuthStorage";
import { useTranslation } from "react-i18next";
import Error from "common/Error";

const ResetPassword = () => {
  const location = useLocation()
  const [language, setLanguage] = useState("English");
  const [isLoder, setIsLoder] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ResetPasswordSchema = yup.object({
    termsCondition: yup
      .boolean()
      .oneOf([true], t("SignUp.Errors.termsCondition")),
    password: yup
      .string()
      .required(t("SignUp.Errors.password"))
      .min(8, "Must be more than 8 characters"),

  });
  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  useEffect(() => {
    if (AuthStorage.getStorageData(STORAGEKEY.lang)) {
      if (AuthStorage.getStorageData(STORAGEKEY.lang) === "en-us") {
        setLanguage('English')
      } else if (AuthStorage.getStorageData(STORAGEKEY.lang) === "de-DE") {
        setLanguage('Deutsch')
      }
    }
  }, [AuthStorage.getStorageData(STORAGEKEY.lang)])

  return (
    <div className="signup-bg">
      {isLoder && <Loader />}
      <div className="signup-header">
        <Link to="/">
          <img src="./assets/img/new-logo.png" alt="no image find" width="100x" /></Link>
        <div className="d-flex align-items-center">
          <DropdownMenu onSelect={(e) => setLanguage(e)} value={language} />
          <OutlineButton
            btnTxt="Sign up"
            onClick={() => {
              navigate("/signup");
            }}
          />
        </div>
      </div>
      <div className="bg-img">
        <Container>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="signup-popup">
              <div className="signup-body signup-reset-body">
                <h1 className="password-heading reset-heading">
                  {t("resetPassword.heder")}
                </h1>
                <p className="password-desc reset-desc">
                  {t("resetPassword.account_exists_for")}{" "}
                  <a href="/">
                    <b>{location?.state?.email?.email}</b>
                  </a>{" "}
                  {t("resetPassword.instructions")}
                </p>
                <div className="button-main-box">
                  <Button
                    className="back-to-Sign"
                    onClick={() => navigate('/')}
                  >
                    {t("resetPassword.btn_back_to_ign_in")}
                  </Button>
                  <a href="/" className="back-sign back-sign-reset">
                    {t("resetPassword.resend")}
                  </a>
                </div>
              </div>
            </div>
            {errors?.password && <Error message={errors?.password?.message} />}
          </form>
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;

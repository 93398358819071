import React from "react";
import { Route, Routes, useLocation } from "react-router";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import ForgotPassword from "./login/ForgotPassword";
import ResetPassword from "./login/ResetPassword";
import AuthSetPassword from "./login/AuthSetPassword";

const Index = () => {
  const location = useLocation();
  const forLayout = ["/signup", "/", "/forgot-password", "/reset-password", "/set-password"];

  return (
    <>
      {forLayout.includes(location.pathname) ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-password" element={<AuthSetPassword />} />
        </Routes>
      ) : (
        null
      )}
    </>
  );
};

export default Index;

import DropdownMenu from "common/Dropdown";
import OutlineButton from "common/OutlineButton";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ReactComponent as EmailIcon } from "../../assets/img/email_icon.svg";
import { ReactComponent as EmailIconActive } from "../../assets/img/email_icon_black.svg";
import "../../App.css";
import { useTranslation } from "react-i18next";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import ContinueButton from "pages/componets/continueButton";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { toast } from "react-toastify";
import { Loader } from "../signup/Loader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Error from "common/Error";
import { ApiPostNoAuth } from "../../helper/API/ApiData";
import { appSettings } from "helper/appSettings";

const ForgotPassword = () => {
  const [isLoder, setIsLoder] = useState(false);
  const { t } = useTranslation();

  const SignupSchema = yup.object({
    email: yup.string().email().required(t("SignUp.Errors.email")),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const [language, setLanguage] = useState("English");
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthStorage.getStorageData(STORAGEKEY.lang)) {
      if (AuthStorage.getStorageData(STORAGEKEY.lang) === "en-us") {
        setLanguage('English')
      } else if (AuthStorage.getStorageData(STORAGEKEY.lang) === "de-DE") {
        setLanguage('Deutsch')
      }
    }
  }, [AuthStorage.getStorageData(STORAGEKEY.lang)])
  const handleForgotPassword = () => {
    setIsLoder(true);
    const body = {
      email: getValues('email'),
    };
    ApiPostNoAuth(`${appSettings?.API_BASE_URL}user-service/auth/forgot-password`, body)
      .then((res) => {
        setIsLoder(false);
        // toast.success(res.metadata.res_msg, {
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
        // navigate('/');
        return res;
      })
      .catch((err) => {
        toast.error(t("SignUp.Toast.error", err), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsLoder(false);
        return err;
      });
  }

  return (
    <div className="signup-bg">
      <div className="signup-header">
        <Link to="/">
          <img
            src="./assets/img/new-logo.png"
            alt="no image find"
            width="100x"
          />
        </Link>
        <div className="d-flex align-items-center">
          <DropdownMenu onSelect={(e) => setLanguage(e)} value={language} />
          <OutlineButton
            btnTxt="Sign up"
            onClick={() => {
              navigate("/signup");
            }}
          />
        </div>
      </div>
      <div className="bg-img">
        <Container>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="signup-popup">
              <div className="signup-body">
                <h1 className="password-heading">{t("forgotPassword.heder")}</h1>
                <p className="password-desc">
                  {t("forgotPassword.text")}
                </p>
                <div className="Signup-form mt-2">
                  <AuthTextBoxWrapper
                    placeholderImage={<EmailIcon />}
                    focusPlaceholderImage={<EmailIconActive />}
                  >
                    <input
                      type="text"
                      placeholder={t("forgotPassword.work_email_placeholder")}
                      name="email"
                      {...register("email")}
                      autoFocus
                    />
                  </AuthTextBoxWrapper>
                  {errors?.email && <Error message={errors?.email?.message} />}
                  <ContinueButton
                    onSubmit={handleSubmit((data) => {
                      handleForgotPassword(data)
                      navigate('/reset-password', {
                        state: {
                          email: data
                        }
                      })
                    })}
                  >
                    {t("forgotPassword.btn_continue")}
                  </ContinueButton>
                </div>

                <Link to="/" className="back-sign">
                  {t("forgotPassword.back_to_sign_in")}
                </Link>
              </div>
            </div>
          </form>
        </Container>
      </div>
      {isLoder && <Loader />}
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthStorage from "helper/AuthStorage";
import { useTranslation } from "react-i18next";
import { ReactComponent as FrameIcon } from "../../assets/img/id.svg";
import { ReactComponent as FrameIconBlack } from "../../assets/img/frame_icon_black.svg";
import SignupCommon from "./SignUpCommon";
import { useEffect } from "react";
import Error from "common/Error";
import ContinueButton from "pages/componets/continueButton";
import AuthTextBoxWrapper from "pages/componets/authTextBoxWrapper";
import STORAGEKEY from "config/APP/app.config";
import ReactGA from "react-ga4";

const CompanyNameSign = ({ onNext }) => {
  const { t } = useTranslation();
  const [storageData, setStorageData] = useState();

  useEffect(() => {
    setStorageData(AuthStorage.getStorageJsonData(STORAGEKEY.userData));
  }, []);

  const SignupSchema = yup.object({
    // name: yup.string().required(t("SignUp.Errors.name")),
    // surname: yup.string().required(t("SignUp.Errors.surName")),
    // phone: yup.string().required(t("SignUp.Errors.phoneNumber")),
    job_title: yup.string().required(t("SignUp.Errors.job_title")),
    // email: yup.string().email().required(t("SignUp.Errors.email")),
    // password: yup.string().required(t("SignUp.Errors.password")),
    // password: yup.string().required(t("SignUp.Errors.password")).matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //     t("SignUp.Errors.passwordValidation")
    // ),
    // passwordConfirm: yup
    //   .string()
    //   .required(t("SignUp.Errors.confirmPassword"))
    //   .oneOf(
    //     [yup.ref("password"), null],
    //     t("SignUp.Errors.confirmPasswordValidation")
    //   ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  return (
    <SignupCommon>
      <form onSubmit={(e) => e.preventDefault()}>
        <h2 className="main-heading">{t("SignUp.CompanyName")}</h2>
        <h3>{t("SignUp.CompanyInfo")}</h3>

        <p className="sub-heading sub-heading-left">
          {t("companyDetail.text")}
        </p>

        <AuthTextBoxWrapper
          placeholderImage={<FrameIcon />}
          focusPlaceholderImage={<FrameIconBlack />}
        >
          <input
            type="text"
            placeholder={t("companyDetail.company_name_placeholder")}
            name="job_title"
            {...register("job_title")}
            onFocus={() => {}}
            autoFocus
          />
        </AuthTextBoxWrapper>
        {errors?.job_title && <Error message={errors?.job_title?.message} />}
        <ContinueButton
          onSubmit={handleSubmit((data) => {
            ReactGA.event({
              action: "Entered Comapny's name",
              category: "Sign up",
              value: "",
            });
            // AuthStorage.setStorageJsonData(STORAGEKEY.signupStep, [1, 2]);
            AuthStorage.setStorageJsonData(STORAGEKEY.userData, {
              ...storageData,
              job_title: data?.job_title,
            });
            localStorage.setItem(STORAGEKEY.signupStep, [1, 2]);
            // localStorage.setItem(
            //   STORAGEKEY.userData,
            //   { ...storageData, job_title: data?.job_title }
            // );
            onNext(3);
          })}
        />
      </form>
    </SignupCommon>
  );
};
export default CompanyNameSign;

import React from "react";
import { ReactComponent as ErrorIcon } from "../assets/img/error.svg";

const Error = ({message}) => {
  return (
    <span className="error_message">
      <ErrorIcon />
      {message}
    </span>
  );
};

export default Error;


import { useEffect, useState } from "react";

export const useTimer = (propMinutes, propSeconds) => {

  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(propMinutes);
  const [seconds, setSeconds] = useState(propSeconds);

  useEffect(() => {
    setMinutes(propMinutes);
  }, [propMinutes])

  useEffect(() => {
    setSeconds(propSeconds)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return { minutes, setMinutes, otp, setOtp, seconds, setSeconds }

}
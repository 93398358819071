import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { ApiPostNoAuth } from "helper/API/ApiData";
import { appSettings } from "helper/appSettings"
import { t } from "i18next";
import { toast } from "react-toastify";
import STORAGEKEY from "config/APP/app.config";
import AuthStorage from "helper/AuthStorage";

export const loginUserAPI = createAsyncThunk(
    'authentication/loginUserAPI',
    async (data) => {
        try {
            let request = data[0];
            let isSocial = request.hasOwnProperty('idToken');
            let payload = {};
            if (isSocial) {
                payload = { token: request.idToken };
            } else {
                payload = {
                    email: request.email,
                    password: request.password
                };
            }
            let url = `${appSettings.API_BASE_URL}user-service/auth/login`;
            if (data[1]) {
                url = url.concat(`?joinToken=${data[1]}`)
            }

            return await axios.post(url, payload)
                .then((res) => {
                    AuthStorage.setToken(res?.data?.response?.token)
                    AuthStorage.setStorageData(STORAGEKEY.refreshToken, res.headers[STORAGEKEY.refreshToken])
                    return res.data
                })
                .catch((err) => {
                    toast.error(t("SignIn.Toast.error"), {
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return err
                });

        } catch (e) {
            console.log('err', e);
        }
    }
)

export const signUpUserAPI = createAsyncThunk('authentication/loginUserAPI',
    async (data, thunkAPI) => {

        delete data?.[0]?.passwordConfirm;
        const body = {
            // ...data[0],
            // name: "name",
            // surname: "surname",
            // phone: "phone",
            // avatar: "avatar",
            // comments: "comments",
            // admin_id: 2,
            // type: "pro",
            token: data[0].password
        };
        ApiPostNoAuth(`${appSettings.API_BASE_URL}user-service/auth/portal/register`, body)
            .then((res) => {
                toast.success(t("SignUp.Toast.success"), {
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                AuthStorage.setToken(res?.data?.token)
                window.location = appSettings.PORTAL_APP_URL;
                return res
            })
            .catch((err) => {
                toast.error(t("SignUp.Toast.error", err), {
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return err;
            });

    }
)

export const authSlice = createSlice({
    name: "authSlice",
    initialState: { entities: [], loading: false },
    reducers: {
    },
    extraReducers: {
        [loginUserAPI.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.value += action.payload;
        },
        [loginUserAPI.reject]: (state, action) => {
            state.isLoading = false;
        },
        [loginUserAPI.pending]: (state, action) => {
            state.isLoading = true;
        },
    },
})

export default authSlice.reducer;
import DropdownMenu from "common/Dropdown";
import OutlineButton from "common/OutlineButton";
import STORAGEKEY from "config/APP/app.config";
import AuthStorage from "helper/AuthStorage";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Loader } from "./Loader";
import Cookies from "js-cookie";

const SignupCommon = ({ children, isLoader }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState("English");

  useEffect(() => {
    if (AuthStorage.getStorageData(STORAGEKEY.lang)) {
      if (AuthStorage.getStorageData(STORAGEKEY.lang) === "en-us") {
        setLanguage("English");
      } else if (AuthStorage.getStorageData(STORAGEKEY.lang) === "de-DE") {
        setLanguage("Deutsch");
      }
    }
  }, [AuthStorage.getStorageData(STORAGEKEY.lang)]);

  return (
    <>
      <div className="signup-bg">
        {isLoader ? <Loader /> : null}
        <div className="signup-header">
          <Link to="/">
            <img
              src="./assets/img/new-logo.png"
              alt="no image find"
              width="100"
            />
          </Link>
          {/* <DropdownMenu onSelect={(e) => setLanguage(e)} value={language} /> */}
          <OutlineButton
            btnTxt={t("SignUp.btn_text_sign_in")}
            onClick={() => {
              navigate("/");
              localStorage.removeItem(STORAGEKEY.signupStep);
            }}
          />
        </div>
        <div className="bg-img">
          <div className="login_container">
            <Container>
              <div className="signup-popup">
                <div className="signup-body">
                  <div className="Signup-form">{children}</div>
                </div>
              </div>
            </Container>
            <div className="login_side">
              <img
                width="320"
                src="./assets/img/login-side-image.png"
                alt=""
                style={{ marginBottom: "20px" }}
              />
              <div
                className="login_text"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2 className="header">Enrich Loyalty With Gamification!</h2>
                <h6
                  className="subheader"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ul style={{ textAlign: "start", width: "300px" }}>
                    <li>Create your branded game</li>
                    <li>Launch a campaign within minutes</li>
                    <li>Stay informed with real-time results</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignupCommon;

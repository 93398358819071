import { useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/img/right-arrow.svg";
import { ReactComponent as LinearRight } from "../../../assets/img/LinearRight.svg";
import "./index.scss";

const ContinueButton = ({
  onSubmit,
  text = "Continue",
  arrowVisible = true,
}) => {
  const [isHover, setIsHover] = useState(false);


  const handleClick = () => {

    onSubmit();
  };

  return (
    <button
      type="submit"
      className={`authActionButton ${isHover ? "active" : ""}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
    >
      <div className="margin" />
      <div className="gradientBG">
        <div className="button">
          <span className="continueText">{text}</span>
          {arrowVisible && (!isHover ? <RightArrow /> : <LinearRight />)}
        </div>
      </div>
    </button>
  );
};

export default ContinueButton;

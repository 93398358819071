// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4Vky2-YFLyTNbqC4Ew6HaNdSjB9ucLTs",
  authDomain: "boomio-e7162.firebaseapp.com",
  projectId: "boomio-e7162",
  storageBucket: "boomio-e7162.appspot.com",
  messagingSenderId: "996647792074",
  appId: "1:996647792074:web:6c5d5e9d365201adf7b333",
  measurementId: "G-DGKKQQYHN5",
};
// const firebaseConfig = {
//     apiKey: "AIzaSyAIItT-4dn8P_ijkiF8hQGlYhYNjYZWCrw",
//     authDomain: "boomio-web-authentication.firebaseapp.com",
//     projectId: "boomio-web-authentication",
//     storageBucket: "boomio-web-authentication.appspot.com",
//     messagingSenderId: "533940056542",
//     appId: "1:533940056542:web:b06a93c07392d6b1bfed4f",
//     measurementId: "G-7J83GC6VN8"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyAIItT-4dn8P_ijkiF8hQGlYhYNjYZWCrw",
//     authDomain: "boomio-web-authentication.firebaseapp.com",
//     projectId: "boomio-web-authentication",
//     storageBucket: "boomio-web-authentication.appspot.com",
//     messagingSenderId: "533940056542",
//     appId: "1:533940056542:web:b06a93c07392d6b1bfed4f",
//     measurementId: "G-7J83GC6VN8"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

import { yupResolver } from '@hookform/resolvers/yup';
import Error from 'common/Error';
import { ApiPost, ApiPostNoAuth } from 'helper/API/ApiData';
import { appSettings } from 'helper/appSettings';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MenuItem, Select } from "@mui/material";
import * as yup from "yup";

const HelpModal = ({ show, onHide }) => {

    // const topics = ['Choose Topic', 'Topic1', 'Topic2', 'Topic3', 'Other']
    // const [topic, setTopic] = useState(topics[0])

    const Help = yup.object({
        // title: yup.string().required('Please select topic').notOneOf(['Choose Topic'], "Please select topic"),
        title: yup.string().required('Please select topic'),
        email: yup.string().email().required('Enter your email '),
        body: yup.string().required('Please describe your issue'),
    });

    const {
        register, handleSubmit, reset, values, formState: { errors } } = useForm({
            resolver: yupResolver(Help),
        });

    // const topicValue = register("title", { required: true })

    const onHandleSubmit = async (data) => {
        await ApiPostNoAuth(`${appSettings.API_BASE_URL}user-service/help`, data).then((res) => {
            toast.success("Submitted successfully.", {
                closeOnClick: true,
                pauseOnHover: true,
            });
            onHide()
            reset(values)
        }).catch((error) => {
            // console.log('error', error)
            toast.error("An error occurred", {
                closeOnClick: true,
                pauseOnHover: true,
            });
        })
    }

    const onCancel = () => {
        reset(values)
        onHide()
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onCancel}
            className="change_password"
        >
            <Modal.Header className="close" closeButton>
                <p>
                    Help
                </p>
            </Modal.Header>
            <Modal.Body className="SegmentModal">
                <form action="" >
                    <div className=' mb-3'>
                        <p className='title-lable'>E-mail
                        </p>
                        <input
                            type="email"
                            className='model-input'
                            name="email"
                            placeholder="E-mail"
                            {...register("email")}
                        />
                        {errors?.email && (<Error message={errors?.email?.message} />)}
                    </div>
                    <div className=' mb-3'>
                        <p className='title-lable'>What topic we can help you with?
                        </p>
                        <input
                            type="text"
                            className='model-input'
                            name="title"
                            placeholder="Topic"
                            {...register("title")}
                        />
                        {/* <Select
                            className="selectbox"
                            name="title"
                            value={topic}
                            {...topicValue}
                            onChange={(e) => { topicValue.onChange(e); setTopic(e.target.value) }}
                            displayEmpty
                            style={{ width: "100%" }}
                        >
                            {topics.map((t) => (
                                <MenuItem
                                    key={t}
                                    value={t}
                                >{t}</MenuItem>
                            ))}
                        </Select> */}
                        {errors?.title && (<Error message={errors?.title?.message} />)}
                    </div>
                    <div className=' mb-3'>
                        <p className='title-lable'>
                            Tell us more about what we can help you with
                        </p>
                        <textarea
                            rows={3}
                            style={{ height: 'auto' }}
                            className='model-input'
                            name="newpassword"
                            placeholder="Describe your issue..."
                            {...register("body")}
                        />
                        {errors?.body && (<Error message={errors?.body?.message} />)}
                    </div>
                    <div className='start-promotional-campaign-footer gap-2' style={{ border: 'none' }} >
                        <Button
                            onClick={handleSubmit((data) => {
                                onHandleSubmit(data)
                                // setTopic(topics[0])
                            })}
                            style={{ width: '100px' }}
                            className="button-8559F3 boomio-white"
                        >
                            Send
                        </Button>
                        <Button
                            onClick={() => {
                                onCancel()
                                // setTopic(topics[0])
                            }}
                            style={{ width: '100px' }}
                            className="button-8559F3 boomio-white"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default HelpModal